@import '~@angular/material/theming';

$custom-typography: mat-typography-config(
  $font-family: 'Noto Sans Japanese',
  $headline: mat-typography-level(1em, 1.5em, 700),
  $body-1: mat-typography-level(1em, 1.5em, 400),
  $title: mat-typography-level(1em, 1.5em, 400),
);

@include mat-core($custom-typography);

$light-theme-primary: mat-palette($mat-cyan);
$light-theme-accent: mat-palette($mat-orange, A200, A100, A400);
$light-theme-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$light-theme: mat-light-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-accent,
      warn: $light-theme-warn,
    ),
  )
);
$dark-theme: mat-dark-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-accent,
      warn: $light-theme-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.theme-light {
  @include mat-slider-theme($light-theme);
}

.theme-dark,
.theme-dark-inverse,
.theme-ns,
.theme-black {
  @include mat-slider-theme($dark-theme);
}
