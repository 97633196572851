@import './brisk_common';

@import '~@angular/cdk/overlay-prebuilt.css';

@each $themeName, $pallet in $color-pallet {
  body.theme-#{$themeName} {
    @each $name, $value in $pallet {
      --brisk-theme-#{$name}: #{$value};
    }
  }
}

.u-price-color-positive {
  @include color('price-color-positive');
  @include fill('price-color-positive');
}

.u-windows-chrome .theme-ns .u-price-color-positive {
  font-weight: 500;
}

.u-price-color-negative {
  @include color('price-color-negative');
  @include fill('price-color-negative');
}

.wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected).u-qr-row-bid-bg {
  @include background-color('qr-bid-background');
}

.wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected).u-qr-row-ask-bg {
  @include background-color('qr-ask-background');
}

/* チャートの色設定 */
.c-chart__candle-stick--positive {
  @include fill('chart-positive-fill');
  @include stroke('chart-positive-stroke');
}

.c-chart__candle-stick--negative {
  @include fill('chart-negative-fill');
  @include stroke('chart-negative-stroke');
}

.c-chart__quantity {
  @include fill('chart-quantity-fill');
  @include stroke('chart-quantity-stroke');
}

.c-chart__quantity--open,
.c-chart__quantity--close {
  @include fill('chart-open-close-quantity-fill');
  @include stroke('chart-open-close-quantity-stroke');
}

.c-chart__line-chart {
  @include fill('line-chart-fill');
  @include stroke('line-chart-stroke');
  stroke-width: 1.5;
}

.c-chart__line-chart--industry {
  @include fill('line-chart-industry-fill');
  @include stroke('line-chart-industry-stroke');
  stroke-width: 1.5;
}

.c-dropdown-popup {
  padding: 0;
  margin-top: -1px;
  border-top-left-radius: 0;
  box-shadow: 0.32em 0.32em 0.32em rgba(0, 0, 0, 0.3);
  @include border(solid 1px, 'btn-border' 'primary');
}

.dropdown-menu {
  @include color('btn-color' 'primary');
  @include background-color('btn-background' 'background');
}

.c-dropdown-popup.c-dropdown--options {
  margin-top: 0px;
  @include color('foreground');
  @include background-color('background');
  @include border(solid 1px, 'primary');
}

.theme-ns .c-dropdown-popup.c-dropdown--options {
  border-color: white;
}

.c-dropdown-popup > button {
  padding: 0.4rem 0.7rem;
  @include color('btn-color' 'primary');
  @include border-bottom(solid 1px, 'btn-border' 'primary');
  outline: none;
  @include background-color('btn-background' 'background');
}

.c-dropdown-popup > button:last-child {
  @include border-bottom(none 0px, 'btn-border' 'primary');
}

.c-dropdown-popup > button:hover {
  @include background-color('btn-hover' 'primary-hover');
  transition: 0.2s;
}

/* スクロールバーの設定 */

// For Chrome
::-webkit-scrollbar {
  @include width('scroll-size');
  @include height('scroll-size');
}

::-webkit-scrollbar-thumb {
  @include background('scroll-foreground');
}

::-webkit-scrollbar-track {
  @include background('scroll-background');
}

// For Gecko
.wj-flexgrid > div > [wj-part='root'],
cdk-virtual-scroll-viewport,
.c-ita-scroll-wrapper {
  scrollbar-width: thin;
}

@each $theme, $colors in $color-pallet {
  .theme-#{$theme} .wj-flexgrid > div > [wj-part='root'],
  .theme-#{$theme} cdk-virtual-scroll-viewport,
  .theme-#{$theme} .c-ita-scroll-wrapper {
    scrollbar-color: map-get($colors, 'scroll-foreground') map-get($colors, 'scroll-background');
    // rem is unsupported
    // scrollbar-width: map-get($colors, 'scroll-size');
  }
}

.u-mini-ita .c-ita-scroll-wrapper {
  scrollbar-width: none; /* Safari/Chrome */
  -ms-overflow-style: none; /* Edge */
}

.u-mini-ita .c-ita-scroll-wrapper::-webkit-scrollbar {
  display: none; /* Firefox */
}

/* END スクロールバーの設定 */

body {
  @include background-color('background');
  @include color('foreground');
  @include scroll-base-color('background');
}

a {
  color: rgb(83, 139, 176);
}

a:hover {
  color: darken(rgb(83, 139, 176), 10%);
}

.wj-content {
  @include background-color('background', null, !important);
  @include color('foreground', null, !important);
  @include border-color('border');
}

.wj-control {
  @include background-color('background', null);
  @include color('foreground', null);
  @include border-color('border');
}

.form-control {
  @include background-color('background', null, !important);
  @include color('foreground', null, !important);
  @include border-color('border', null, !important);
}

.form-control:disabled {
  @include color('foreground-disabled', null, !important);
  @include background-color('background-disabled', null, !important);
}

.form-control::placeholder {
  @include color('placeholder-color', null, !important);
  opacity: 1;
}

.wj-form-control::placeholder {
  @include color('placeholder-color', null, !important);
  opacity: 1;
}

.wj-cell,
.wj-cell.wj-header {
  @include border-right-color('grid-border', null, !important);
  @include border-bottom-color('grid-border', null, !important);
}

.wj-cell {
  @include color('grid-foreground', null, !important);
}

.wj-cell:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
  @include background-color('grid-background', null, !important);
}

.wj-cell.wj-header {
  font-weight: 400;
  @include background-color('grid-header-background', null, !important);
  @include color('grid-header-foreground', null, !important);
}

.wj-flexgrid .wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
  @include background-color('grid-group-background', null, !important);
  @include color('grid-group-foreground', null, !important);
}

.wj-flexgrid .wj-group.wj-state-selected,
.wj-flexgrid .wj-group.wj-state-multi-selected {
  @include background-color('grid-group-selected-background', null, !important);
  @include color('grid-group-selected-foreground', null, !important);
}

ngb-tabset {
  .nav-tabs {
    user-select: none;
  }

  .nav-tabs .nav-link {
    cursor: pointer;
  }
}

ngb-tabset.c-brisk-tab {
  .nav-tabs {
    border-bottom: none;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs .nav-link {
    @include color('tab-color' 'primary-hover');
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 0.1464vw;
    text-align: center;
  }

  .nav-tabs .nav-link.active {
    @include color('tab-active-color' 'primary', null, null);
    @include border-bottom-color('tab-active-border' 'primary', null, null);
    @include background-color('tab-active-background' 'background', null, null);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 0.1464vw;
  }

  .nav-tabs .nav-link:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    transition: 0.5s;
    border-bottom-width: 0.1464vw;
  }
}

.wj-state-selected,
.wj-state-multi-selected {
  @include color('grid-selected-foreground');
  @include background-color('grid-selected-background');
}

.wj-sort-asc .wj-glyph-up,
.wj-sort-desc .wj-glyph-up {
  @include color('grid-sort-color');
}

.wj-flexchart .wj-label {
  @include fill('chart-color');
}

// For filter's combobox
.wj-control .wj-input-group-btn > .wj-btn.wj-btn-default {
  @include color('btn-color' 'foreground');
  @include background-color('btn-background' 'background');
}

.wj-control .wj-input-group .wj-input-group-btn:last-child:not(:first-child) > .wj-btn {
  @include border-left-color('border-color');
}

.wj-menu-items.c-context-menu {
  @include background-color('btn-background' 'background', null, !important);
  @include color('btn-color' 'primary', null, !important);
  @include border-color('btn-border' 'primary', null, !important);
  padding: 0 !important;
}

.wj-menu-items.c-context-menu .wj-listbox-item {
}

.wj-menu-items.c-context-menu .wj-listbox-item:hover:not(:disabled) {
  @include background-color('btn-hover' 'primary-hover', null, !important);
  transition: 0.2s;
}

// Vg-Grid
.vg-grid {
  @include border-color('grid-border');
}

.vg-grid-cell {
  @include border-bottom-color('grid-border');
  @include border-right-color('grid-border');
  @include color('grid-foreground');
  @include background-color('background');
}

.vg-grid--striped .vg-grid-row--even:not(.vg-grid-row-selected) .vg-grid-cell {
  @include background-color('grid-background-striped');
}

.vg-grid-cell-header {
  font-weight: 400;
  @include background-color('grid-header-background', null);
  @include color('grid-header-foreground', null);
}

.vg-grid-filter-active.vg-grid-cell-header,
.vg-grid-sort-active.vg-grid-cell-header {
  font-weight: 700;
}

.vg-grid-cell-group-header,
.vg-grid-row-selected .vg-grid-cell.vg-grid-cell-group-header {
  @include background-color('grid-group-background', null);
  @include color('grid-group-foreground', null);
}

.vg-grid-row-selected .vg-grid-cell {
  @include color('grid-selected-foreground');
  @include background-color('grid-selected-background');
}

.vg-grid-cell-input {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  @include background-color('grid-background');
  @include color('grid-foreground');
  border: none;
  outline: none;
  appearance: none;
}

.vg-grid-filter-popover {
  //z-index: 1500; // WijmoのPopupが1500なので同値にする必要がある
  margin: 0px;
  @include border-color('border');
  @include background-color('background');
  @include color('foreground');
  font-size: inherit;
  width: 20rem;
  max-width: none;

  .arrow {
    display: none;
  }

  .popover-body {
    padding: 0;
    @include color('foreground');
  }
}

.market-popover {
  margin: 0px;
  @include border-color('border');
  @include background-color('background');
  @include color('foreground');
  font-size: inherit;
  width: 50rem;
  max-width: none;
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.3);

  .arrow {
    display: none;
  }

  .popover-body {
    padding: 0.3rem;
    @include color('foreground');
    position: relative;
  }
}

.vg-grid-filter-button {
  @include color('foreground');
}

.c-icon-btn {
  color: inherit;
  cursor: pointer;
  opacity: 0.7;
  background-color: transparent;
  border-width: 0;
  user-select: none;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 0 0 0.5rem !important;
  outline: none !important;
}

.c-icon-btn:hover {
  opacity: 1;
}

.modal-header .close {
  cursor: pointer;
  @include color('foreground');
}

.c-assist-popover.c-tutorial-popover {
  max-width: 36rem;
  min-width: 15rem;
}

.c-tooltip {
  font-size: 1rem;

  &.show {
    opacity: 1;
  }

  .arrow {
    display: none;
  }

  .tooltip-inner {
    @include background('background');
    @include color('foreground');
    @include border(1px solid, 'border');
    border-radius: 4px;
    box-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
    padding: 4px 6px 3px;

    max-width: 25rem;
    text-align: left;
  }
}

.wj-tooltip {
  @include background('background');
  @include color('foreground');
  @include border(1px solid, 'border');
  padding: 4px 6px 3px;
  border-radius: 4px;

  max-width: 20rem;
}

.brisk-issue-code-input-dropdown {
  overflow-y: scroll;

  .wj-listbox-item {
    min-width: 15rem;
    overflow: hidden;
  }
}

/*
drag drop
 */
.p-brisk-dragging {
  position: absolute;
  z-index: 9000;
  margin: 0;
  padding: 0;
  border: none;
  pointer-events: none;
  opacity: 0.8;

  & > :first-child {
    pointer-events: none !important;
    overflow: hidden !important;
    transform: translate(-50%, -50%) !important;
  }
}

/**
 * ngx-contextmenu
 */
.ngx-contextmenu {
  * {
    outline: none;
  }

  .dropdown {
    outline: none;
  }

  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    min-width: unset;
    @include border-color('primary');

    .dropdown-item {
      @include color('primary');
    }

    .dropdown-item:hover {
      @include background-color('primary-hover');
    }

    .dropdown-item.active {
      @include color('primary');
      @include background-color('primary-hover');
    }

    .dropdown-item.active:hover {
      @include color('primary');
      @include background-color('primary-hover');
    }
  }
}

@import './lib/dialog/dialog';
