$font-family-sans-serif: 'Noto Sans Japanese', sans-serif;
$font-family-monospace: 'Noto Sans Mono Japanese', monospace;

$btn-border-radius-sm: 0;

/** Bootstrap 4をベースライブラリとして利用 (ボタン・ダイアログなど) */
@import '~bootstrap/scss/bootstrap';

@import '~@grapecity/wijmo.styles/wijmo.css';

@import '~apps/fita3/src/variables';

$base-border-width: 1px;
$btn-border-width: 1px;

@import 'material-theme';
@import 'golden-layout-theme';

html.fita3-font-xs {
  font-size: 10px;
}

html.fita3-font-s {
  font-size: 12px;
}

html.fita3-font-m {
  font-size: 14px;
}

html.fita3-font-l {
  font-size: 16px;
}

html.fita3-font-xl {
  font-size: 20px;
}

html:not(.fita3-font-xs):not(.fita3-font-s):not(.fita3-font-m):not(.fita3-font-l):not(.fita3-font-xl):not(.normal-view) {
  font-size: 0.732vw;
}

html.normal-view {
  font-size: 0.732vw * 1.2;
}

body,
html {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

body.u-test-single-window {
  app-fita4-root {
    top: 0;
    left: 0;
    bottom: 50vh;
    right: 0;
    position: absolute;
    height: 50vh;
  }
  app-fita4-ita-root {
    top: 50vh;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 50vh;
  }
}

.u-windows-chrome body.theme-ns {
  filter: blur(0);
  overflow: hidden;
}

.vg-grid-cell-input {
  width: 100%;
}

.wj-control a.wj-btn,
.wj-viewer .wj-control a.wj-applybutton,
.wj-control button.wj-btn:not(.wj-btn-default),
.wj-viewer .wj-control button.wj-applybutton:not(.wj-btn-default) {
  font-size: 1rem;
}

.wj-content {
  border-width: $base-border-width;
  border-radius: 0.25vw;
}

.wj-control {
  border-width: $base-border-width;
  border-radius: 0.25vw;
}

.form-control {
  border-width: $base-border-width;
  border-radius: 0.25vw;
}

.wj-cell,
.wj-cell.wj-header {
  border-right-width: $base-border-width;
  border-right-style: solid;
  border-bottom-width: $base-border-width;
  border-bottom-style: solid;
}

.wj-cell {
  padding: 0px;
}

ngb-tabset.c-brisk-tab {
  .nav-tabs .nav-link,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom-width: 0.1464vw;
    margin-right: 0.0732vw;
    width: 6.2rem;
    padding: 0.1vw 0 0.17vw 0;
    margin-top: 0.55rem;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link.active {
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-item.show .nav-link:hover {
    transition: 0.5s;
  }
}

ngb-tabset.c-brisk-tab.c-brisk-tab--wide-tab {
  .nav-tabs .nav-link,
  .nav-tabs .nav-item.show .nav-link {
    width: 9rem;
  }
}

ngb-tabset {
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;

  > .nav-tabs {
    grid-row: 1;
    grid-column: 1;
  }

  > .tab-content {
    grid-row: 2;
    grid-column: 1;
  }

  .tab-pane {
    width: 100%;
    height: 100%;
  }
}

wj-auto-complete {
  border-radius: 0.25rem !important;
}

wj-auto-complete.grid {
  border-radius: 0 !important;
}

.wj-control .wj-input-group .wj-form-control {
  padding: 0 0.3em 0 0.3em;
}

/* Wijmo FlexGrid x方向のスクロールバーを禁止 */
.wj-flexgrid [wj-part='root'] {
  overflow-x: hidden !important;
}

.wj-btn {
  padding: 0 !important;
  min-width: 0 !important;
}

.wj-input-group-btn:last-child:not(:first-child) > .wj-btn {
  border-left-width: 1px !important;
  width: 1.5vw;
  min-width: 0;
}

.wj-input-group-btn:first-child:not(:last-child) > .wj-btn {
  border-right-width: 1px !important;
  width: 1.5vw;
  min-width: 0;
}

.wj-input-group-btn {
  min-width: 0 !important;
}

wj-flex-grid .wj-cell.add-padding,
.c-vs-grid__cell.add-padding,
.add-padding {
  padding-left: 0.2vw;
  padding-right: 0.2vw;
}

wj-popup {
  min-width: 0 !important;
}

input[type='checkbox'],
input[type='radio'] {
  height: 1rem !important;
  width: 1rem !important;
}

* {
  box-sizing: border-box;
}

.wj-sort-asc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  .wj-glyph-up {
    margin-bottom: 0.4vw;
    margin-right: 0.1vw;
  }
}

.wj-sort-desc {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  .wj-glyph-down {
    margin-top: 0.4vw;
    margin-right: 0.1vw;
  }
}

.wj-flexgrid .wj-cell.wj-filter-on,
.wj-flexgrid .wj-cell.wj-filter-off {
  padding-right: 0.3rem !important;
}

.wj-listbox {
  padding: 0.2vw;
}

// カラムフィルタの削除ボタンを修正する
.wj-columnfiltereditor [wj-part='div-sort'] {
  display: none;
}

.wj-cell.wj-header.wj-sort-asc,
.wj-cell.wj-header.wj-sort-desc,
.wj-cell.wj-header.wj-filter-on {
  font-weight: 700;
}

.wj-group > button {
  margin-left: 0.4vw;
}

// Issue #24
body > div > div.wj-cell {
  display: none;
}

.c-btn {
  display: inline-block;
  cursor: pointer;
  border-style: solid;
  border-width: $btn-border-width;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  box-shadow: none !important;
}

.c-btn:focus {
  outline: none;
}

@mixin c-btn-color($name, $color: null, $background-color: null, $border-color: null, $hover-color: null, $hover-background-color: null) {
  .c-btn--#{$name} {
    transition: none;
    @if ($background-color) {
      @include background-color($background-color);
    }
    @if ($color) {
      @include color($color);
    }
    @if ($border-color) {
      @include border-color($border-color);
    }

    &:hover:not(:disabled) {
      @if ($hover-background-color) {
        @include background-color($hover-background-color);
      }
      @if ($hover-color) {
        @include color($hover-color);
      }
      transition: 0.2s;
    }

    &:disabled {
      cursor: default;
      opacity: 0.65;
    }
  }
}

@include c-btn-color(
  'danger',
  $color: 'danger-color',
  $background-color: 'background',
  $border-color: 'danger-color',
  $hover-background-color: 'danger-hover-background'
);

.c-btn--default {
  transition: none;
  @include background-color('btn-background' 'background');
  @include color('btn-color' 'primary');
  @include border-color('btn-border' 'primary');
  font-size: 1em;
}

.c-btn--default:hover:not(:disabled) {
  @include background-color('btn-hover' 'primary-hover');
  transition: 0.2s;
}

.c-btn--default:disabled {
  cursor: default;
  opacity: 0.65;
}

/* .active is for Bootstrap */
.c-btn--default.is-active,
.c-btn--default.active {
  @include color('btn-active-color' 'background');
  @include background-color('btn-active-background' 'primary');
  @include border-color('btn-border' 'primary');
}

.theme-ns .c-btn--default.is-active,
.theme-ns .c-btn--default.active,
.theme-black .c-btn--default.is-active,
.theme-black .c-btn--default.active {
  text-shadow: 1px 1px 0px #ffffff;
}

.c-btn--default.is-active:hover:not(:disabled),
.c-btn--default.active:hover:not(:disabled) {
  @include background-color('btn-active-hover' 'btn-hover' 'primary-hover');
  transition: 0.2s;
  @include color('btn-active-hover-text' 'btn-color' 'primary');
}

.c-btn--buy {
  @include background-color('btn-background' 'background');
  @include border-color('btn-buy-border');
  @include color('btn-buy-color');
}

.c-btn--buy.is-active,
.c-btn--buy.active {
  @include color('btn-active-color' 'background');
  @include background-color('btn-buy-color');
  @include border-color('btn-border' 'btn-buy-color');
}

.c-btn--buy:hover:not(:disabled) {
  @include background-color('btn-buy-hover' 'secondary-hover');
  transition: 0.2s;
}

.c-btn--buy.is-active:hover:not(:disabled),
.c-btn--buy.active:hover:not(:disabled) {
  @include background-color('btn-buy-hover-bg' 'primary-hover');
  transition: 0.2s;
}

.c-btn--sell {
  @include background-color('btn-background' 'background');
  @include border-color('btn-sell-border');
  @include color('btn-sell-color');
}

.c-btn--sell:hover:not(:disabled) {
  @include background-color('btn-sell-hover' 'secondary-hover');
  transition: 0.2s;
}
.c-btn--sell.is-active,
.c-btn--sell.active {
  @include color('btn-active-color' 'background');
  @include background-color('btn-sell-color');
  @include border-color('btn-border' 'btn-sell-color');
}

.c-btn--sell.is-active:hover:not(:disabled),
.c-btn--sell.active:hover:not(:disabled) {
  @include background-color('btn-sell-hover-bg' 'primary-hover');
  transition: 0.2s;
}

.c-btn--red {
  @include background-color('btn-background' 'background');
  @include border-color('btn-border' 'secondary');
  @include color('btn-color' 'secondary');
}

.c-btn--red:hover:not(:disabled) {
  @include background-color('btn-hover' 'secondary-hover');
  transition: 0.2s;
}

.c-assist-popover-button {
  position: absolute;
  background-color: transparent;
  z-index: 999999;
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
}

.c-assist-popover {
  max-width: 29rem;
  max-height: none;
  min-width: 24rem;
  border-radius: 0.7rem;
  display: inline-block;
  overflow: visible;

  .popover-body {
    padding: 0.91rem 0.91rem 0.68rem 0.91rem;
  }
}

.c-assist-popover__description {
}

.c-assist-popover__buttons {
  margin: 1.5rem 0 0.5rem 0;
  text-align: right;
}

.c-assist-popover__button {
  @include color('popover-foreground');
  @include background-color('popover-background');
  @include border(solid 0.082vw, 'popover-foreground');
  border-radius: 0.2vw;
  font-size: 1rem;
  padding: 0.2rem 0.8rem 0.2rem 0.8rem;
  outline: none;
  margin-left: 0.8rem;
}

.c-assist-popover__button:hover {
  @include background-color('primary-hover');
  text-decoration: none;
  transition: 0.2s;
}

.c-assist-popover__img {
  width: 5rem;
  height: 5rem;
  margin-left: -0.3vw;
}

.c-assist-popover__text {
  font-size: 1rem;
  margin: 0;
  min-height: 1.4rem;
  line-height: 1.8rem;
}

.c-assist-popover__text--broadened {
  line-height: 2.4rem;
}

.c-assist-popover__container {
  display: flex;
}

.c-assist-popover--default {
  @include background-color('popover-background');
  @include border-color('popover-background');

  &.bs-popover-top .arrow::before,
  &.bs-popover-top .arrow::after {
    @include border-top-color('popover-background');
  }

  &.bs-popover-bottom .arrow::after {
    @include border-bottom-color('popover-background');
  }

  &.bs-popover-right .arrow::before,
  &.bs-popover-right .arrow::after {
    @include border-right-color('popover-background');
  }

  &.bs-popover-left .arrow::before,
  &.bs-popover-left .arrow::after {
    @include border-left-color('popover-background');
  }

  .popover-body {
    @include color('popover-foreground');
  }
}

.c-assist-popover--small {
  min-width: 10rem;
}

.c-assist-popover--ita {
  max-width: 26rem;
}

.c-assist-popover--copy-paste {
  min-width: auto;
  max-width: 21.5rem;
}

ngb-tabset.c-brisk-tab.u-tab-buttons {
  display: block;

  .nav-tabs {
    font-size: 1rem;
    padding-top: 0px;
    margin-bottom: 0px;
    width: 16.53rem;
    height: 2.45rem;
    position: absolute;
    right: 2.4rem;
    top: 0;
    align-items: center;
  }

  .tab-content {
    position: absolute;
    top: 2.45rem;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .nav-tabs .nav-link {
    margin-top: 0rem;
    @include color('btn-color' 'primary');
    @include background-color('btn-background' 'background');
    border-radius: 0;
    @include border(solid 0.082vw, 'btn-border' 'primary');
    font-weight: 500;
    font-size: 1rem;
    width: 5.5rem;
    margin-left: -0.164vw;
    height: 1.6rem;
    line-height: 1.16rem;
  }

  .nav-tabs .nav-item:last-child .nav-link {
    padding-left: 0.1vw !important;
  }

  .nav-tabs .nav-link:hover {
    @include background-color('btn-hover' 'primary-hover');
    transition: 0.5s;
  }

  .nav-tabs .nav-link.active {
    @include background-color('btn-active-background' 'primary');
    @include color('btn-active-color' 'background');
  }
}

.theme-ns ngb-tabset.c-brisk-tab.u-tab-buttons .nav-tabs .nav-link.active {
  text-shadow: 1px 1px 0px #ffffff;
}

wj-input-number.u-custom-input-number {
  display: block !important;
}

wj-input-number.u-custom-input-number .wj-btn {
  height: calc(1.6rem - 2 * 0.082vw) !important;
  min-height: 0 !important;
  border: none !important;
  @include color('btn-color' 'primary', null, !important);
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

wj-input-number.u-custom-input-number .wj-btn[aria-label='減少値'] {
  font-size: 2rem !important;
  font-weight: 400 !important;
  padding: 0 0 0.34rem 0 !important;
  height: 1.6rem !important;
  width: 1.4rem !important;
  border-style: solid !important;
  border-width: 0.082vw !important;
  @include border-color('btn-background' 'primary', null, !important);
  margin-right: -0.082vw !important;
  border-radius: 0;
}

wj-input-number.u-custom-input-number .wj-btn[aria-label='値を増加させる'] {
  padding: 0 !important;
  height: 1.6rem !important;
  width: 1.4rem !important;
  border-style: solid !important;
  border-width: 0.082vw !important;

  @include border-color('btn-background' 'primary', null, !important);
  border-radius: 0;
}

wj-input-number.u-custom-input-number .wj-form-control {
  min-height: 0 !important;
  height: 1.6rem !important;
  padding: 0 !important;
  text-align: center !important;
  border-top-style: solid !important;
  border-top-width: 0.082vw !important;
  @include border-top-color('btn-background' 'primary', null, !important);
  border-bottom-style: solid !important;
  border-bottom-width: 0.082vw !important;
  @include border-bottom-color('btn-background' 'primary', null, !important);
  margin-right: -0.082vw !important;
  border-radius: 0;
}

wj-input-number.u-custom-input-number .wj-btn:hover {
  @include background-color('primary-hober', null, !important);
  transition: 0.2s !important;
  cursor: pointer !important;
}

.p-main-split > as-split-area {
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
  overflow: hidden !important;
}

.p-main-split > .as-split-gutter {
  box-sizing: border-box;
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
  flex-basis: 1vw !important;
  border-top-right-radius: 1vw !important;
  border-top-left-radius: 1vw !important;
  background-position-y: 0.2vw !important;
  background-size: 1.5vw;
  z-index: 499 !important;
  margin-bottom: -0.35vw;
  @include background-color('background', null, !important);
}

.theme-light .p-main-split > .as-split-gutter {
  box-shadow: 0 -0.3vw 0.4vw rgba(0, 0, 0, 0.2) !important;
}

.theme-dark .p-main-split > .as-split-gutter,
.theme-dark-inverse .p-main-split > .as-split-gutter,
.theme-ns .p-main-split > .as-split-gutter,
.theme-black .p-main-split > .as-split-gutter {
  box-shadow: 0 -0.3vw 0.4vw rgba(128, 128, 128, 0.8) !important;
}

.p-main-split > .as-split-gutter > .as-split-gutter-icon {
  background-position: center center !important;
  margin-top: 0.2vw;
  background-size: 1.8vw;
  height: 100%;
  width: 4rem !important;
  margin-top: -0.23rem !important;
}

.btn:focus,
.btn:active {
  outline: none;
}

.c-btn__header {
  height: calc(1.6em + 2px);
}

wj-input-number.u-custom-input-number-gl-header {
  $height: calc(1.6em + 2px);
  display: block !important;
  $border-width: 1px;
  border-radius: 0 !important;
  border: none !important;
  height: $height !important;

  .wj-input-group {
    height: $height !important;
  }

  .wj-btn {
    height: calc(#{$height} - 2 * #{$border-width}) !important;
    min-height: 0 !important;
    border: none !important;
    @include color('btn-color' 'primary', null, !important);
    font-weight: 400 !important;
  }

  .wj-btn[aria-label='減少値'] {
    font-weight: 400 !important;
    padding: 0 0 0.34em 0 !important;
    height: $height !important;
    width: 1.4em !important;
    border-style: solid !important;
    border-width: $border-width !important;
    @include border-color('btn-background' 'primary', null, !important);
    margin-right: -$border-width !important;

    border-radius: 0 !important;
  }

  .wj-btn[aria-label='値を増加させる'] {
    padding: 0 !important;
    height: $height !important;
    width: 1.4em !important;
    border-style: solid !important;
    border-width: $border-width !important;

    @include border-color('btn-background' 'primary', null, !important);
    border-radius: 0 !important;
  }

  .wj-form-control {
    min-height: 0 !important;
    height: $height !important;
    padding: 0 !important;
    text-align: center !important;
    border-top-style: solid !important;
    border-top-width: $border-width !important;
    @include border-top-color('btn-background' 'primary', null, !important);
    border-bottom-style: solid !important;
    border-bottom-width: $border-width !important;
    @include border-bottom-color('btn-background' 'primary', null, !important);
    margin-right: -$border-width !important;
    @include border-color('btn-background' 'primary', null, !important);

    border-radius: 0;
  }

  .wj-btn:hover {
    @include background-color('primary-hover', null, !important);
    transition: 0.2s !important;
    cursor: pointer !important;
  }
}

app-summary-list {
  #summaryPortfolioWrapper {
    border-width: 1px !important;
    border-style: solid !important;
    height: 100% !important;
    border-radius: inherit !important;

    #summaryPortfolio {
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
    }

    #summaryHeaderLine {
      left: 0.5rem;
    }
  }
}

.wj-inputnumber {
  .input-number-postpend {
    display: table-cell;
    cursor: text;
    width: 1%;
  }
}
.wj-inputnumber.u-unit-yen {
  .input-number-postpend:after {
    content: '円';
    margin-right: 0.5em;
    width: 1%;
  }
}

.wj-inputnumber.u-unit-kabu:not(.u-unit-kabu--small) {
  .input-number-postpend:after {
    content: '株';
    margin-right: 0.5em;
    width: 1%;
  }
}

.wj-inputnumber.u-unit-kabu.u-unit-kabu--small {
  .input-number-postpend:after {
    content: '株';
    margin-right: 0.2em;
    width: 1%;
  }
}

.u-buy-sell-color-buy {
  @include color('buy-color');
}

.u-buy-sell-color-sell {
  @include color('sell-color');
}
.c-btn--grid {
  height: 100%;
  display: block;
  width: 100%;
  line-height: 100%;
  text-align: center;
  padding: 0;
}

@mixin wj-input-small-field {
  // line-height + border
  height: calc(1.5em + 2px);
  border-radius: unset;
  .wj-input .wj-input-group input.wj-form-control {
    min-height: 1.5em;
  }
  .wj-input .wj-input-group .wj-input-group-btn .wj-btn {
    min-height: 1.5em;
    width: 1.5em;
  }

  &.grid {
    border: none;
    height: 100%;
    vertical-align: unset;
  }
}

/* selectと同じ大きさになる wj-combobox */
.u-small-combo-box {
  @include wj-input-small-field;
  @for $i from 1 through 30 {
    &--w-#{$i} {
      // text  + padding + drondown-button + border
      width: calc(#{0.5 * $i}em + 0.6em + 1.5em + 2px);
    }
  }
}

.u-small-input-number {
  @include wj-input-small-field;
  @for $i from 1 through 12 {
    &--w-#{$i} {
      width: #{$i}em;
    }
  }
}

.u-error-input {
  &.wj-control {
    @include border-color(red);
  }
}

// TODO: コンポーネントに置きたいか、WijmoのPopupとの相性で反映されない
.c-grid-column-dialog {
  .p-index-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    @include background-color('grid-header-background');
  }
}

.vg-grid--no-cell-border {
  .vg-grid-cell {
    border: none;
  }
}

.u-fixed-row {
  @include border-bottom(2px solid, primary);
}

.c-btn--open-e-shiten {
  background-color: #43a047;
  border-color: #43a047;
  color: white;
  width: 13.2rem !important;
}

.c-btn--open-e-shiten:hover:not(:disabled) {
  background-color: #6ca16e;
  transition: 0.2s;
}

.c-btn--order-tutorial {
  @include color('red', null, !important);
}

.c-btn--order-tutorial:hover:not(:disabled) {
  @include background-color('btn-hover' 'secondary-hover');
  transition: 0.2s;
}
