$color-pallet: (
  dark: (
    background: #272f36,
    background-disabled: darken(#272f36, 30%),
    foreground-light: invert(#555555),
    foreground-disabled: rgba(0, 0, 0, 0.3),
    foreground: white,
    border: rgb(221, 221, 221),
    primary: rgb(83, 139, 176),
    primary-hover: rgb(204, 222, 235),
    btn-active-hover: darken(rgb(83, 139, 176), 10%),
    btn-active-hover-text: #272f36,
    secondary: rgb(176, 83, 95),
    secondary-hover: rgb(235, 204, 208),
    grid-foreground: rgb(255, 255, 255),
    grid-background: #272f36,
    grid-background-striped: lighten(#272f36, 3%),
    grid-selected-background: #666666,
    grid-selected-forground: invert(rgb(57, 87, 115)),
    grid-sort-color: gray,
    grid-border: #848484,
    grid-header-background: #444444,
    grid-header-foreground: #eeeeee,
    grid-group-background: #555555,
    grid-group-foreground: #eeeeee,
    grid-group-selected-background: #555555,
    grid-group-selected-foreground: #eeeeee,
    grid-disabled-background-color: lighten(#272f36, 5.5%),
    popover-background: rgba(125, 170, 231, 0.898),
    popover-foreground: white,
    scroll-foreground: rgb(164, 164, 164),
    scroll-background: rgb(32, 32, 32),
    scroll-size: 0.5rem,
    gutter-color: #555,
    price-color-positive: rgb(251, 194, 191),
    price-color-negative: rgb(173, 241, 179),
    ita-high-price-border: rgb(230, 162, 162),
    ita-low-price-border: rgb(150, 190, 150),
    ita-price-range-border: white,
    ita-background-color-history: #333333,
    ita-background-color-peg-up: adjust-color(#272f36, $red: 21),
    ita-background-color-peg-down: adjust-color(#272f36, $green: 12),
    ita-background-color-limit-up: rgb(230, 162, 162),
    ita-background-color-limit-down: rgb(150, 190, 150),
    ita-background-color-last-price: #5f5f5f,
    ita-background-color-delta-increase: #660000,
    ita-background-color-delta-decrease: #006600,
    ita-background-color-quantity: rgba(251, 178, 88, 0.7),
    ita-over-under-hover-color: #dddddd,
    qr-ask-background: #660000,
    qr-bid-background: #006600,
    qr-background-color-history: #333333,
    bar-chart-threshold-up-color: #ee1010,
    bar-chart-threshold-down-color: #30a030,
    bar-chart-up-color: #ee1010,
    bar-chart-down-color: #30a030,
    bar-chart-zero-color: #272f36,
    bar-chart-undefined-color: #202020,
    chart-color: invert(#666),
    chart-axis-line: invert(#666),
    chart-positive-fill: #660000,
    chart-negative-fill: #006600,
    chart-positive-stroke: #993333,
    chart-negative-stroke: #339933,
    chart-quantity-fill: rgba(251, 178, 88, 0.7),
    chart-open-close-quantity-fill: #cccccc,
    chart-quantity-stroke: #fbb258,
    chart-open-close-quantity-stroke: #bbbbbb,
    line-chart-fill: rgba(136, 189, 230, 0.5),
    line-chart-stroke: rgba(136, 189, 230, 0.5),
    line-chart-industry-fill: rgba(251, 178, 88, 0.5),
    line-chart-industry-stroke: rgba(251, 178, 88, 0.5),
    mini-chart-axis-line: #666,
    mini-chart-price-up-fill: rgba(241, 184, 181, 0.8),
    mini-chart-price-down-fill: rgba(163, 221, 169, 0.8),
    lift-background-color: #660000,
    hit-background-color: #006600,
    hit-lift-border-color: #ababab,
    portfolio-lift-background-color: #660000,
    portfolio-hit-background-color: #006600,
    portfolio-lift-border-color: rgb(191, 111, 105),
    portfolio-hit-border-color: rgb(69, 144, 97),
    summary-list-border-color: #c9c9c9,
    stock-list-border-color: #a4c0dd,
    close-button: rgb(83, 139, 176),
    close-button-hover: rgb(204, 222, 235),
    switch-button: rgb(83, 139, 176),
    sp-header-background: lighten(#272f36, 10%),
    placeholder-color: #6c757d,
    gl-header-background: lighten(#272f36, 4%),
    gl-drag-proxy-content: rgba(0, 0, 0, 0.9),
    gl-drop-target-indicator: #000000,
    gl-drop-inner-opacity: 0.2,
    gl-splitter-color: #000000,
    gl-splitter-hover-color: #777,
    btn-buy-color: rgb(251, 194, 191),
    btn-buy-border: rgb(251, 194, 191),
    btn-buy-hover: lighten(#272f36, 10%),
    btn-buy-hover-bg: scale-color(rgb(251, 194, 191), $lightness: -10%),
    btn-sell-color: rgb(173, 241, 179),
    btn-sell-border: rgb(173, 241, 179),
    btn-sell-hover: lighten(#272f36, 10%),
    btn-sell-hover-bg: scale-color(rgb(173, 241, 179), $lightness: -10%),
    buy-color: rgb(251, 194, 191),
    sell-color: rgb(173, 241, 179),
    'red': #f44336,
    danger-color: #f44336,
    danger-hover-background: scale-color(#f44336, $lightness: -10%),
  ),
  dark-inverse: (
    background: #272f36,
    foreground-light: invert(#555555),
    foreground-disabled: rgba(0, 0, 0, 0.3),
    foreground: white,
    border: rgb(221, 221, 221),
    primary: rgb(83, 139, 176),
    primary-hover: rgb(204, 222, 235),
    btn-active-hover: darken(rgb(83, 139, 176), 10%),
    btn-active-hover-text: #272f36,
    secondary: rgb(176, 83, 95),
    secondary-hover: rgb(235, 204, 208),
    grid-foreground: rgb(255, 255, 255),
    grid-background: #272f36,
    grid-background-striped: lighten(#272f36, 3%),
    grid-selected-background: #666666,
    grid-selected-forground: invert(rgb(57, 87, 115)),
    grid-sort-color: gray,
    grid-border: #848484,
    grid-header-background: #444444,
    grid-header-foreground: #eeeeee,
    grid-group-background: #555555,
    grid-group-foreground: #eeeeee,
    grid-group-selected-background: #555555,
    grid-group-selected-foreground: #eeeeee,
    grid-disabled-background-color: lighten(#272f36, 5.5%),
    popover-background: rgba(125, 170, 231, 0.898),
    popover-foreground: white,
    scroll-foreground: rgb(164, 164, 164),
    scroll-background: rgb(32, 32, 32),
    scroll-size: 0.5rem,
    gutter-color: #555,
    price-color-positive: rgb(173, 241, 179),
    price-color-negative: rgb(251, 194, 191),
    ita-high-price-border: rgb(150, 190, 150),
    ita-low-price-border: rgb(230, 162, 162),
    ita-price-range-border: white,
    ita-background-color-history: #333333,
    ita-background-color-peg-up: adjust-color(#272f36, $green: 12),
    ita-background-color-peg-down: adjust-color(#272f36, $red: 21),
    ita-background-color-limit-up: rgb(150, 190, 150),
    ita-background-color-limit-down: rgb(230, 162, 162),
    ita-background-color-last-price: #5f5f5f,
    ita-background-color-delta-increase: #006600,
    ita-background-color-delta-decrease: #660000,
    ita-background-color-quantity: rgba(251, 178, 88, 0.7),
    ita-over-under-hover-color: #dddddd,
    qr-ask-background: #006600,
    qr-bid-background: #660000,
    qr-background-color-history: #333333,
    bar-chart-threshold-up-color: #30a030,
    bar-chart-threshold-down-color: #ee1010,
    bar-chart-up-color: #30a030,
    bar-chart-down-color: #ee1010,
    bar-chart-zero-color: #272f36,
    bar-chart-undefined-color: #202020,
    chart-color: invert(#666),
    chart-axis-line: invert(#666),
    chart-positive-fill: #006600,
    chart-negative-fill: #660000,
    chart-positive-stroke: #339933,
    chart-negative-stroke: #993333,
    chart-quantity-fill: rgba(251, 178, 88, 0.7),
    chart-open-close-quantity-fill: #cccccc,
    chart-quantity-stroke: #fbb258,
    chart-open-close-quantity-stroke: #bbbbbb,
    line-chart-fill: rgba(136, 189, 230, 0.5),
    line-chart-stroke: rgba(136, 189, 230, 0.5),
    line-chart-industry-fill: rgba(251, 178, 88, 0.5),
    line-chart-industry-stroke: rgba(251, 178, 88, 0.5),
    mini-chart-price-up-fill: rgba(163, 221, 169, 0.8),
    mini-chart-price-down-fill: rgba(241, 184, 181, 0.8),
    mini-chart-axis-line: #666,
    lift-background-color: #006600,
    hit-background-color: #660000,
    hit-lift-border-color: #ababab,
    portfolio-lift-background-color: #006600,
    portfolio-hit-background-color: #660000,
    portfolio-lift-border-color: rgb(69, 144, 97),
    portfolio-hit-border-color: rgb(191, 111, 105),
    summary-list-border-color: #c9c9c9,
    stock-list-border-color: #a4c0dd,
    close-button: rgb(83, 139, 176),
    close-button-hover: rgb(204, 222, 235),
    switch-button: rgb(83, 139, 176),
    sp-header-background: lighten(#272f36, 10%),
    placeholder-color: #6c757d,
    gl-header-background: lighten(#272f36, 4%),
    gl-drag-proxy-content: rgba(0, 0, 0, 0.9),
    gl-drop-target-indicator: #000000,
    gl-drop-inner-opacity: 0.2,
    gl-splitter-color: #000000,
    gl-splitter-hover-color: #777,
    btn-sell-color: rgb(251, 194, 191),
    btn-sell-border: rgb(251, 194, 191),
    btn-sell-hover: lighten(#272f36, 10%),
    btn-sell-hover-bg: scale-color(rgb(173, 241, 179), $lightness: -10%),
    btn-buy-color: rgb(173, 241, 179),
    btn-buy-border: rgb(173, 241, 179),
    btn-buy-hover: lighten(#272f36, 10%),
    btn-buy-hover-bg: scale-color(rgb(251, 194, 191), $lightness: -10%),
    sell-color: rgb(251, 194, 191),
    buy-color: rgb(173, 241, 179),
    'red': #f44336,
    danger-color: #f44336,
    danger-hover-background: scale-color(#f44336, $lightness: -10%),
  ),
  light: (
    foreground: #212529,
    foreground-light: #555555,
    foreground-disabled: rgba(0, 0, 0, 0.3),
    background: white,
    background-disabled: #e9ecef,
    border: rgb(221, 221, 221),
    primary: rgb(83, 139, 176),
    primary-hover: rgb(204, 222, 235),
    btn-active-hover: darken(rgb(83, 139, 176), 10%),
    btn-active-hover-text: white,
    secondary: rgb(176, 83, 95),
    secondary-hover: rgb(235, 204, 208),
    grid-foreground: rgb(0, 0, 0),
    grid-background: rgb(255, 255, 255),
    grid-background-striped: #f6f6f6,
    grid-selected-background: #dde8f4,
    grid-selected-forground: rgb(57, 87, 115),
    grid-sort-color: gray,
    grid-border: #d4d4d4,
    grid-header-background: #eeeeee,
    grid-header-foreground: #444444,
    grid-group-background: #dddddd,
    grid-group-foreground: #444444,
    grid-group-selected-background: #dddddd,
    grid-group-selected-foreground: #444444,
    grid-disabled-background-color: #f6f6f6,
    popover-background: rgba(125, 170, 231, 0.898),
    popover-foreground: white,
    scroll-foreground: rgb(160, 160, 160),
    scroll-background: rgb(240, 240, 240),
    scroll-size: 0.5rem,
    gutter-color: #eee,
    price-color-positive: rgb(210, 60, 60),
    price-color-negative: rgb(30, 120, 62),
    ita-high-price-border: rgb(230, 162, 162),
    ita-low-price-border: rgb(150, 190, 150),
    ita-price-range-border: black,
    ita-background-color-history: #f7f7f7,
    ita-background-color-peg-up: #fff7f7,
    ita-background-color-peg-down: #f7fff7,
    ita-background-color-limit-up: rgb(230, 162, 162),
    ita-background-color-limit-down: rgb(150, 190, 150),
    ita-background-color-last-price: #ddd,
    ita-background-color-delta-increase: #ffdddd,
    ita-background-color-delta-decrease: #ddffdd,
    ita-background-color-quantity: rgba(251, 178, 88, 0.7),
    ita-over-under-hover-color: #666666,
    qr-bid-background: rgba(163, 221, 169, 0.4),
    qr-ask-background: rgba(241, 184, 181, 0.4),
    qr-background-color-history: #f7f7f7,
    bar-chart-threshold-up-color: #e05754,
    bar-chart-threshold-down-color: #338b39,
    bar-chart-up-color: #f18885,
    bar-chart-down-color: #63ab69,
    bar-chart-zero-color: #fff,
    bar-chart-undefined-color: #ddd,
    chart-color: #666,
    chart-axis-line: rgba(red, 0.125),
    chart-positive-fill: rgb(241, 184, 181),
    chart-negative-fill: rgb(163, 221, 169),
    chart-positive-stroke: #db9a96,
    chart-negative-stroke: #82c091,
    chart-quantity-fill: rgba(251, 178, 88, 0.7),
    chart-open-close-quantity-fill: #cccccc,
    chart-quantity-stroke: #fbb258,
    chart-open-close-quantity-stroke: #bbbbbb,
    line-chart-fill: rgba(136, 189, 230, 0.5),
    line-chart-stroke: rgba(136, 189, 230, 0.5),
    line-chart-industry-fill: rgba(251, 178, 88, 0.5),
    line-chart-industry-stroke: rgba(251, 178, 88, 0.5),
    mini-chart-price-up-fill: rgba(241, 184, 181, 0.8),
    mini-chart-price-down-fill: rgba(163, 221, 169, 0.8),
    mini-chart-axis-line: rgb(255, 224, 224),
    lift-background-color: rgb(241, 184, 181),
    hit-background-color: rgb(163, 221, 169),
    hit-lift-border-color: #7f7f7f,
    portfolio-lift-background-color: rgba(241, 184, 181, 0.4),
    portfolio-hit-background-color: rgba(163, 221, 169, 0.4),
    portfolio-lift-border-color: rgb(191, 111, 105),
    portfolio-hit-border-color: rgb(69, 144, 97),
    summary-list-border-color: #c9c9c9,
    stock-list-border-color: #a4c0dd,
    close-button: #666,
    close-button-hover: #ddd,
    switch-button: rgb(83, 139, 176),
    sp-header-background: gainsboro,
    placeholder-color: #6c757d,
    gl-header-background: #f8f8f8,
    gl-drag-proxy-content: rgba(0, 0, 0, 0.2),
    gl-drop-target-indicator: rgba(0, 0, 0, 0.4),
    gl-drop-inner-opacity: 0.1,
    gl-splitter-color: #999999,
    gl-splitter-hover-color: #bbbbbb,
    btn-buy-color: rgb(210, 60, 60),
    btn-buy-border: rgb(210, 60, 60),
    btn-buy-hover: scale-color(rgb(210, 60, 60), $lightness: 90%),
    btn-buy-hover-bg: scale-color(rgb(210, 60, 60), $lightness: -10%),
    btn-sell-color: rgb(30, 120, 62),
    btn-sell-border: rgb(30, 120, 62),
    btn-sell-hover: scale-color(rgb(30, 120, 62), $lightness: 90%),
    btn-sell-hover-bg: scale-color(rgb(30, 120, 62), $lightness: -10%),
    buy-color: rgb(210, 60, 60),
    sell-color: rgb(30, 120, 62),
    'red': #f44336,
    danger-color: #f44336,
    danger-hover-background: scale-color(#f44336, $lightness: 90%),
  ),
  ns: (
    background: #000,
    foreground-light: #fff,
    foreground-disabled: rgba(0, 0, 0, 0.3),
    foreground: white,
    border: rgb(221, 221, 221),
    primary: rgb(83, 139, 176),
    primary-hover: rgb(204, 222, 235),
    /** 松井特殊部分 */ btn-border: #000000,
    btn-background: #f5f5f5,
    btn-color: #000000,
    btn-active-color: #000000,
    btn-active-background: #a9a9a9,
    btn-hover: #ddd,
    tab-active-color: #000,
    tab-active-background: #f5f5f5,
    tab-active-border: #f5f5f5,
    tab-color: #fff,
    switch-button-inverse: #000,
    btn-active-hover: darken(#a9a9a9, 10%),
    secondary: rgb(176, 83, 95),
    secondary-hover: rgb(235, 204, 208),
    grid-foreground: rgb(255, 255, 255),
    grid-background: #000000,
    grid-background-striped: #080808,
    grid-selected-background: #666666,
    grid-selected-forground: invert(rgb(57, 87, 115)),
    grid-sort-color: gray,
    grid-border: #696969,
    grid-header-background: #000000,
    grid-header-foreground: #ffffff,
    grid-group-background: #333333,
    grid-group-foreground: #ffffff,
    grid-group-selected-background: #333333,
    grid-group-selected-foreground: #ffffff,
    grid-disabled-background-color: #222,
    popover-background: rgba(125, 170, 231, 0.898),
    popover-foreground: white,
    scroll-foreground: rgb(164, 164, 164),
    scroll-background: rgb(32, 32, 32),
    scroll-size: 0.5rem,
    gutter-color: #555,
    price-color-positive: #ff0000,
    price-color-negative: #00ff00,
    ita-high-price-border: rgb(230, 162, 162),
    ita-low-price-border: rgb(150, 190, 150),
    ita-price-range-border: white,
    ita-background-color-history: #333333,
    ita-background-color-peg-up: #220000,
    ita-background-color-peg-down: #002200,
    ita-background-color-limit-up: rgb(230, 162, 162),
    ita-background-color-limit-down: rgb(150, 190, 150),
    ita-background-color-last-price: #5f5f5f,
    ita-background-color-delta-increase: #660000,
    ita-background-color-delta-decrease: #006600,
    ita-background-color-quantity: rgba(251, 178, 88, 0.7),
    ita-over-under-hover-color: #dddddd,
    qr-ask-background: #660000,
    qr-bid-background: #006600,
    qr-background-color-history: #333333,
    bar-chart-threshold-up-color: #ee1010,
    bar-chart-threshold-down-color: #30a030,
    bar-chart-up-color: #ee1010,
    bar-chart-down-color: #30a030,
    bar-chart-zero-color: #000000,
    bar-chart-undefined-color: #202020,
    chart-color: invert(#666),
    chart-axis-line: invert(#666),
    chart-positive-fill: #ff0000,
    chart-negative-fill: #00ff00,
    chart-positive-stroke: #ff0000,
    chart-negative-stroke: #00ff00,
    chart-quantity-fill: rgba(251, 178, 88, 0.7),
    chart-open-close-quantity-fill: #cccccc,
    chart-quantity-stroke: #fbb258,
    chart-open-close-quantity-stroke: #bbbbbb,
    line-chart-fill: rgba(136, 189, 230, 0.5),
    line-chart-stroke: rgba(136, 189, 230, 0.5),
    line-chart-industry-fill: rgba(251, 178, 88, 0.5),
    line-chart-industry-stroke: rgba(251, 178, 88, 0.5),
    mini-chart-price-up-fill: rgba(241, 184, 181, 0.8),
    mini-chart-price-down-fill: rgba(163, 221, 169, 0.8),
    mini-chart-axis-line: #666,
    lift-background-color: #660000,
    hit-background-color: #006600,
    hit-lift-border-color: #ababab,
    portfolio-lift-background-color: #660000,
    portfolio-hit-background-color: #006600,
    portfolio-lift-border-color: rgb(191, 111, 105),
    portfolio-hit-border-color: rgb(69, 144, 97),
    summary-list-border-color: #ddd,
    stock-list-border-color: #ddd,
    close-button: #fff,
    close-button-hover: #444,
    switch-button: #fff,
    placeholder-color: #6c757d,
    gl-header-background: lighten(#000000, 20%),
    gl-drag-proxy-content: rgba(0, 0, 0, 0.9),
    gl-drop-target-indicator: #000000,
    gl-drop-inner-opacity: 0.2,
    gl-splitter-color: #000000,
    gl-splitter-hover-color: #777,
    buy-color: #ff0000,
    sell-color: #00ff00,
    'red': #f44336,
    danger-color: #f44336,
  ),
  black: (
    background: #000,
    foreground-light: #fff,
    foreground-disabled: rgba(0, 0, 0, 0.3),
    foreground: white,
    border: rgb(221, 221, 221),
    primary: rgb(83, 139, 176),
    primary-hover: rgb(204, 222, 235),
    /** 松井特殊部分 */ btn-border: #000000,
    btn-background: #f5f5f5,
    btn-color: #000000,
    btn-active-color: #000000,
    btn-active-background: #a9a9a9,
    btn-hover: #ddd,
    tab-active-color: #000,
    tab-active-background: #f5f5f5,
    tab-active-border: #f5f5f5,
    tab-color: #fff,
    switch-button-inverse: #000,
    btn-active-hover: darken(#a9a9a9, 10%),
    secondary: rgb(176, 83, 95),
    secondary-hover: rgb(235, 204, 208),
    grid-foreground: rgb(255, 255, 255),
    grid-background: #000000,
    grid-background-striped: #080808,
    grid-selected-background: #666666,
    grid-selected-forground: invert(rgb(57, 87, 115)),
    grid-sort-color: gray,
    grid-border: #696969,
    grid-header-background: #000000,
    grid-header-foreground: #ffffff,
    grid-group-background: #333333,
    grid-group-foreground: #ffffff,
    grid-group-selected-background: #333333,
    grid-group-selected-foreground: #ffffff,
    grid-disabled-background-color: #222,
    popover-background: rgba(125, 170, 231, 0.898),
    popover-foreground: white,
    scroll-foreground: rgb(164, 164, 164),
    scroll-background: rgb(32, 32, 32),
    scroll-size: 0.5rem,
    gutter-color: #555,
    price-color-positive: #ff2222,
    price-color-negative: #00ff00,
    ita-high-price-border: rgb(230, 162, 162),
    ita-low-price-border: rgb(150, 190, 150),
    ita-price-range-border: white,
    ita-background-color-history: #333333,
    ita-background-color-peg-up: #220000,
    ita-background-color-peg-down: #002200,
    ita-background-color-limit-up: rgb(230, 162, 162),
    ita-background-color-limit-down: rgb(150, 190, 150),
    ita-background-color-last-price: #5f5f5f,
    ita-background-color-delta-increase: #a00000,
    ita-background-color-delta-decrease: #008300,
    ita-background-color-quantity: rgba(251, 178, 88, 0.7),
    ita-over-under-hover-color: #dddddd,
    qr-ask-background: #a00000,
    qr-bid-background: #008300,
    qr-background-color-history: #333333,
    bar-chart-threshold-up-color: #ee1010,
    bar-chart-threshold-down-color: #30a030,
    bar-chart-up-color: #ee1010,
    bar-chart-down-color: #30a030,
    bar-chart-zero-color: #000000,
    bar-chart-undefined-color: #202020,
    chart-color: invert(#666),
    chart-axis-line: invert(#666),
    chart-positive-fill: #ff0000,
    chart-negative-fill: #00ff00,
    chart-positive-stroke: #ff0000,
    chart-negative-stroke: #00ff00,
    chart-quantity-fill: rgba(251, 178, 88, 0.7),
    chart-open-close-quantity-fill: #cccccc,
    chart-quantity-stroke: #fbb258,
    chart-open-close-quantity-stroke: #bbbbbb,
    line-chart-fill: rgba(136, 189, 230, 0.5),
    line-chart-stroke: rgba(136, 189, 230, 0.5),
    line-chart-industry-fill: rgba(251, 178, 88, 0.5),
    line-chart-industry-stroke: rgba(251, 178, 88, 0.5),
    mini-chart-price-up-fill: rgba(241, 184, 181, 0.8),
    mini-chart-price-down-fill: rgba(163, 221, 169, 0.8),
    mini-chart-axis-line: #666,
    lift-background-color: #a00000,
    hit-background-color: #008300,
    hit-lift-border-color: #ababab,
    portfolio-lift-background-color: #a00000,
    portfolio-hit-background-color: #008300,
    portfolio-lift-border-color: rgb(191, 111, 105),
    portfolio-hit-border-color: rgb(69, 144, 97),
    summary-list-border-color: #ddd,
    stock-list-border-color: #ddd,
    close-button: #fff,
    close-button-hover: #444,
    switch-button: #fff,
    placeholder-color: #6c757d,
    gl-header-background: lighten(#000000, 20%),
    gl-drag-proxy-content: rgba(0, 0, 0, 0.9),
    gl-drop-target-indicator: #000000,
    gl-drop-inner-opacity: 0.2,
    gl-splitter-color: #000000,
    gl-splitter-hover-color: #777,
    buy-color: #ff0000,
    sell-color: #00ff00,
    'red': #f44336,
    danger-color: #f44336,
  ),
);

@mixin themify($property, $keys, $prefix: null, $suffix: null, $themes: $color-pallet) {
  @each $theme, $colors in $themes {
    $found: 0;
    @each $key in $keys {
      @if $found == 0 {
        @if map_has_key($colors, $key) {
          $found: 1;
          @if '#{&}'=='body' {
            &.theme-#{$theme} {
              #{$property}: $prefix map-get($colors, $key) $suffix;
            }
          } @else {
            .theme-#{$theme} & {
              #{$property}: $prefix map-get($colors, $key) $suffix;
            }

            :host-context(.theme-#{$theme}) & {
              /*! Dummy comment to avoid merge host-context and other by cssnano */
              #{$property}: $prefix map-get($colors, $key) $suffix;
            }
          }
        }
      }
    }
  }
}

@mixin color($arguments...) {
  @include themify('color', $arguments...);
}

@mixin background($arguments...) {
  @include themify('background-color', $arguments...);
}

@mixin background-color($arguments...) {
  @include themify('background-color', $arguments...);
}

@mixin border($border-style, $arguments) {
  @include themify('border', $arguments, $border-style);
}

@mixin border-bottom($border-style, $arguments) {
  @include themify('border-bottom', $arguments, $border-style);
}

@mixin border-top($border-style, $arguments) {
  @include themify('border-top', $arguments, $border-style);
}

@mixin border-right($border-style, $arguments) {
  @include themify('border-right', $arguments, $border-style);
}

@mixin border-left($border-style, $arguments) {
  @include themify('border-left', $arguments, $border-style);
}

@mixin border-color($arguments...) {
  @include themify('border-color', $arguments...);
}

@mixin border-top-color($arguments...) {
  @include themify('border-top-color', $arguments...);
}

@mixin border-left-color($arguments...) {
  @include themify('border-left-color', $arguments...);
}

@mixin border-bottom-color($arguments...) {
  @include themify('border-bottom-color', $arguments...);
}

@mixin border-right-color($arguments...) {
  @include themify('border-right-color', $arguments...);
}

@mixin scroll-base-color($arguments...) {
  @include themify('scroll-base-color', $arguments...);
}

@mixin width($arguments...) {
  @include themify('width', $arguments...);
}

@mixin height($arguments...) {
  @include themify('height', $arguments...);
}

@mixin fill($arguments...) {
  @include themify('fill', $arguments...);
}

@mixin stroke($arguments...) {
  @include themify('stroke', $arguments...);
}

@mixin background-linear-gradient-markets($key) {
  @each $theme, $colors in $color-pallet {
    @if map_has_key($colors, $key) {
      .theme-#{$theme} &,
      :host-context(.theme-#{$theme}) & {
        background: linear-gradient(
          90deg,
          rgba(map-get($colors, $key), 0) 0,
          rgba(map-get($colors, $key), 0.7) 20%,
          rgba(map-get($colors, $key), 1) 80%
        );
      }
    }
  }
}

@mixin box-shadow($box-style, $arguments) {
  @include themify('box-shadow', $arguments, $box-style);
}
