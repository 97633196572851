@import '../../brisk_common';

/* mat-dialogでbootstrap風の表示にする */
.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
}

.cdk-overlay-container {
  z-index: 1500 !important;
}

/* overlayのanimationの無効化 */
.brisk-dialog-overlay {
  background: rgba(0, 0, 0, 0.3);

  &--strong {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
  }

  &.cdk-overlay-backdrop {
    display: none;
    transition: opacity 1ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &.cdk-overlay-backdrop-showing {
      display: unset;
    }
  }
}

.brisk-dialog {
  $dialog-padding: 1em;

  .mat-dialog-container {
    @include background-color('background');
    @include color('foreground');
    display: block;
    padding: $dialog-padding;
    border-radius: 0.5em;
    box-shadow: 0 6px 13px rgba(0, 0, 0, 0.2);
    @include border(1px solid, border);
  }

  .mat-dialog-title {
    margin: 0 (-$dialog-padding) 0;
    padding: 0 1em 1em;

    display: flex;
    @include border-bottom(1px solid, border);
    align-items: flex-start;
    justify-content: space-between;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }

  .mat-dialog-content {
    margin: 0 (-$dialog-padding);
    padding: $dialog-padding;
  }

  .mat-dialog-actions {
    margin: 0 (-$dialog-padding) (-$dialog-padding);
    padding: $dialog-padding;

    @include border-top(1px solid, border);
    min-height: 2em;

    > *:not(:first-child) {
      margin-left: 0.5em;
    }

    > brisk-checkbox {
      // align="end"によってtextが右寄せになってしまっているのを左寄せに戻す
      // Angular Materialを更新したら外すべき
      // cf. https://github.com/angular/components/pull/24328
      text-align: left;

      margin-right: 0.5em;

      input[type='checkbox'] {
        min-width: 1rem;
      }
    }
  }
}

.brisk-dialog-long-height {
  .mat-dialog-content {
    max-height: 90vh;
  }
}
