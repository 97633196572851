@import '~libs/brisk-common/src/brisk_common';
@import '~golden-layout/src/css/goldenlayout-base.css';

.lm_goldenlayout {
  // Golden Layout in Golden Layout
  .lm_goldenlayout {
    .lm_header {
    }
  }
}

.lm_header {
  @include border(1px solid, 'border');
  @include background('gl-header-background' 'grid-header-background');
}

.lm_content {
  border-top: none;
  @include background('background');
  @include border-bottom(1px solid, 'border');
  @include border-left(1px solid, 'border');
  @include border-right(1px solid, 'border');
  overflow-x: auto;
}

.lm_dragProxy .lm_content {
  @include box-shadow(2px 2px 4px, 'gl-drag-proxy-content');
  box-sizing: border-box;
}

.lm_dropTargetIndicator {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
  @include box-shadow(inset 0 0 30px, 'gl-drop-target-indicator');
  outline: 1px dashed #cccccc;
  margin: 1px;
  transition: all 200ms ease;
}

.lm_dropTargetIndicator .lm_inner {
  @include themify('opacity', 'gl-drop-inner-opacity');
}

.lm_splitter {
  @include background-color('gl-splitter-color');
  opacity: 0.001;
  transition: opacity 200ms ease;
}

.lm_splitter:hover,
.lm_splitter.lm_dragging {
  @include background-color('gl-splitter-hover-color');
  opacity: 1;
}

$tabHeight: 1.6em;

.fita3-font-xs .lm_header {
  height: 21px;

  .lm_tabdropdown_list {
    top: 21px;
  }
}

.fita3-font-s .lm_header {
  height: 25px;

  .lm_tabdropdown_list {
    top: 25px;
  }
}

.fita3-font-m .lm_header {
  height: 29px;

  .lm_tabdropdown_list {
    top: 29px;
  }
}

.fita3-font-l .lm_header {
  height: 34px;

  .lm_tabdropdown_list {
    top: 34px;
  }
}

.fita3-font-xl .lm_header {
  height: 42px;

  .lm_tabdropdown_list {
    top: 42px;
  }
}

.lm_tabdropdown_list {
  .lm_tab {
    @include background('background');
  }
}

.lm_header.lm_selectable {
  cursor: pointer;
}

.lm_header {
  display: flex;
  align-items: flex-end;
  z-index: unset;
  user-select: none;

  .lm_tabs {
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
  }

  .lm_custom_controls {
    height: calc(#{$tabHeight} + 6px);
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 6px;
  }

  .lm_custom_controls.lm_custom_controls--left-align + .lm_controls {
    margin-left: auto;
  }

  .lm_custom_controls.lm_custom_controls--left-align {
    margin-left: 0;
  }

  .lm_controls {
    height: calc(#{$tabHeight} + 6px);
    margin-right: 6px;
  }
  .lm_controls > li {
    height: calc(#{$tabHeight} + 6px);
    position: relative;
  }
}

.lm_header .lm_tab {
  @include color('foreground');
  margin-right: 2px;
  padding-bottom: 4px;

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  @include border-top(1px solid, 'border');
  @include border-right(1px solid, 'border');
  @include border-left(1px solid, 'border');
  text-overflow: ellipsis;
  white-space: nowrap;
  height: $tabHeight;
}

.lm_header .lm_tabs {
  position: unset;
}

.lm_header .lm_tab .lm_title {
  padding-top: 1px;
  margin-right: 4px;
}

.theme-light {
  .lm_header .lm_tab .lm_close_tab {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAIklEQVR4nGNgYGD4z4Ad/Mdg4ODDBXCZRFgCp5EEHQMXBwAQAgz0SVCcggAAAABJRU5ErkJggg==);
  }
}

.theme-dark,
.theme-dark-inverse,
.theme-ns {
  .lm_header .lm_tab .lm_close_tab {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAATElEQVR4nG3OwQ0DMQwDwZGRBtR/j1YJzMc5+IDoR+yCVO29g+pu981MFgqZmRdAfU7+CYWcbF11LwALjpBL0N0qybNx/RPU+gOeiS/+XCRwDlTgkQAAAABJRU5ErkJggg==);
  }
}

.lm_header .lm_tab {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 6px;
}

.lm_header .lm_tab .lm_close_tab {
  margin-left: 0.5em;
  position: inherit;
  width: 11px;
  height: 11px;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.lm_header .lm_tab .lm_close_tab:hover {
  opacity: 1;
}

.lm_header .lm_tab.lm_active {
  @include background('background');
  box-shadow: 2px -2px 2px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: -1px;
  padding-bottom: 5px;
}

.lm_tab.app-fita4-active {
  .lm_title {
    font-weight: 700;
  }

  .lm_title::before {
    content: '*';
  }

  .lm_title::after {
    content: '*';
  }
}

.theme-light {
  .lm_tab.app-fita4-tab-highlight {
    background-color: #ffe6b6;
  }
  .lm_tab.app-fita4-tab-highlight-animation {
    transition: background-color 1s linear;
  }
}

.lm_header .lm_tab.lm_active .lm_close_tab {
  opacity: 1;
}

.lm_stack {
  box-sizing: border-box;
}

.lm_dragProxy.lm_bottom .lm_header .lm_tab.lm_active,
.lm_stack.lm_bottom .lm_header .lm_tab.lm_active {
  box-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.lm_selected .lm_header {
  background-color: #452500;
}

.lm_tab:hover,
.lm_tab.lm_active {
  @include background('background');
}

.theme-light {
  .lm_header .lm_controls .lm_tabdropdown:before {
    color: black;
  }
}

.theme-dark,
.theme-dark-inverse,
.theme-ns {
  .lm_header .lm_controls .lm_tabdropdown:before {
    color: white;
  }
}

.lm_header > .lm_controls {
  position: unset;
}

.lm_controls > li,
.lm_custom_controls > li {
  cursor: pointer;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.4;
  transition: opacity 300ms ease;
}

.lm_controls > li:hover,
.lm_custom_controls > li:hover {
  opacity: 1;
}

.lm_controls .lm_popout {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAANUlEQVR4nI2QMQoAMAwCz5L/f9mOzZIaN0E9UDyZhaaQz6atgBHgambEJ5wBKoS0WaIvfT+6K2MIECN19MAAAAAASUVORK5CYII=);
}

.lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAIklEQVR4nGNkYGD4z0AAMBFSAAOETPpPlEmDUREjAxHhBABPvAQLFv3qngAAAABJRU5ErkJggg==);
}

.lm_controls .lm_close {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVR4nGNgYGD4z4Af/Mdg4FKASwCnDf8JKSBoAtEmEXQTQd8RDCcA6+4Q8OvIgasAAAAASUVORK5CYII=);
}

.lm_maximised .lm_header {
  background-color: #ffffff;
}

.lm_maximised .lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAJklEQVR4nGP8//8/AyHARFDFUFbEwsDAwMDIyIgzHP7//89IlEkApSkHEScJTKoAAAAASUVORK5CYII=);
}

.lm_transition_indicator {
  background-color: #000000;
  border: 1px dashed #555555;
}

.lm_popin {
  cursor: pointer;
}

.lm_popin .lm_bg {
  background: #000000;
  opacity: 0.7;
}

.lm_popin .lm_icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAAWklEQVR4nJWOyw3AIAxDHcQC7L8jbwT3AlJBfNp3SiI7dtRaLSlKKeoA1oEsKSQZCEluexw8Tm3ohk+E7bnOUHUGcNh+HwbBygw4AZ7FN/Lt84p0l+yTflV8AKQyLdcCRJi/AAAAAElFTkSuQmCC);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.lm_popin:hover .lm_icon {
  opacity: 1;
}

/*# sourceMappingURL=goldenlayout-light-theme.css.map */
